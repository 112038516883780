<template>
  <div class="body">
    <div class="container">
      <Carousel :autoplay="5500" :wrap-around="true">
        <Slide :key="slide">
          <div class="carousel__item">
            <div class="image">
              <img src="../assets/images/joao.jpeg" alt="João Pedro Valandro Bertani" />
            </div>
            <div class="description">
              <p class="resume">
                Advogado inscrito na OAB/RS n° 115.449 e na OA (Ordem dos Advogados Portugueses) n° 69564L.
                Graduado em Relações Internacionais (ESPM-Sul) e em Direito (Universidade de Passo Fundo).
                <br><br>Especialista em Direito Agrário e Mestre em Direito dos Negócios Internacionais (Universidad Complutense de Madrid) e em Governo (Universidad de Navarra).
                <br>Foi assessor jurídico no Ministério da Cidadania (2019-2020).
              </p>
              <h1 class="name">Dr. João Pedro Valandro Bertani</h1>
              <p class="role">OAB/RS n° 115.449 <br>OA n° 69564L</p>
            </div>
          </div>
        </Slide>
        <Slide :key="slide">
          <div class="carousel__item">
            <div class="image">
              <img src="../assets/images/mario.jpeg" alt="Mário Luiz Bertani" />
            </div>
            <div class="description">
              <p class="resume">
                Advogado inscrito na OAB/RS n° 35.445, formado em Administração de Empresas (Universidade de Passo Fundo) e em Direito (Universidade de Cruz Alta).
                <br><br>Pós-graduado em Cooperativismo e Direito Empresarial, possui mais de 50 anos de experiência em cooperativismo e direito agrário.
                <br>Ocupou cargos de liderança em diversas cooperativas e entidades.
              </p>
              <h1 class="name">Dr. Mário Luiz Bertani</h1>
              <p class="role">OAB/RS n° 35.445</p>
            </div>
          </div>
        </Slide>
        <Slide :key="slide">
          <div class="carousel__item">
            <div class="image">
              <img src="../assets/images/filipe.jpeg" alt="Filipe Carrion" />
            </div>
            <div class="description">
              <p class="resume">
                Advogado inscrito na OAB/RS n° 127.729, formado pela Pontifícia Universidade Católica do Rio Grande do Sul, com pós-graduação em Gestão Desportiva (Unisinos) e em Relações Institucionais e Governamentais (Mackenzie).
                <br><br>Foi assessor parlamentar e atuação nos setores público e privado.
              </p>
              <h1 class="name">Dr. Filipe Carrion</h1>
              <p class="role">OAB/RS n° 127.729</p>
            </div>
          </div>
        </Slide>

        <template #addons>
          <Navigation v-if="!hideNavigation" />
          <Pagination />
        </template>
      </Carousel>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount, watch } from 'vue';
import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

export default {
  name: 'Team',
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  setup() {
    const hideNavigation = ref(window.innerWidth <= 476);

    const handleResize = () => {
      hideNavigation.value = window.innerWidth <= 476;
    };

    onMounted(() => {
      window.addEventListener('resize', handleResize);
      handleResize();
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', handleResize);
    });

    watch(() => window.innerWidth, handleResize);

    return {
      hideNavigation,
    };
  },
};
</script>

<style scoped>
.body {
  margin: 0 auto;
  max-width: 1440px;
  width: 100%;
  height: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.container {
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.carousel {
  max-width: 1000px;
}
.carousel__item {
  width: 100%;
  height: 720px;
  margin: 0;
  padding: 0 60px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  border-radius: 25px;
  color: var(--text-color-unique);
  background-color: var(--background-ter-color);
}
.carousel__slide {
  padding: 10px;
}
.carousel__pagination {
  margin: 0;
  padding: 15px 0 0 35px;
  justify-content: left;
}
.carousel__prev,
.carousel__next {
  padding: 20px !important;
}
.image {
  margin: 0;
  padding: 0;
  width: 55%;
}
.image img {
  width: 100%;
  aspect-ratio: 500 / 599;
  border-radius: 25px;
  box-shadow: 0 6px 4px rgba(0, 0, 0, 0.35);
}
.description {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  padding: 10px 0 10px 36px;
  width: 45%;
}
.description h1 {
  padding: 0;
  font-size: 32px;
  font-weight: 700;
  line-height: 1.0;
  color: var(--text-color-unique);
  font-family: 'Poppins', sans-serif;
  text-align: left;
}
.description p {
  padding: 0;
  font-size: 22px;
  line-height: 1.3;
  color: var(--text-color-unique);
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  text-align: left;
}
.description .resume {
  padding: 0 0 38px 0;
}
.description .role {
  font-size: 26px;
  padding: 5px 0 0 0;
}
@media only screen and (max-width: 1224px) {
  .carousel {
    width: 90%;
  }
}
@media only screen and (max-width: 964px) {
  .body {
    height: 1100px;
  }
  .carousel {
    width: 95%;
  }
  .carousel__item {
    height: 750px;
    padding: 0 60px 0;
    flex-direction: column;
    background-color: transparent;
  }
  .carousel__pagination {
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 0 0 15px 0;
  }
  .image {
    width: 100%;
  }
  .image img {
    width: 60%;
    aspect-ratio: 238 / 284;
    border-radius: 25px;
    box-shadow: 0 6px 4px rgba(0, 0, 0, 0.35);
  }
  .description {
    padding: 10px;
    width: 100%;
  }
  .description p {
    font-size: 16px;
    text-align: center;
    color: var(--text-color);
  }
  .description h1 {
    font-size: 26px;
    text-align: center;
    color: var(--text-color);
  }
  .description .resume {
    padding: 15px 0 28px 0;
  }
  .description .role {
    font-size: 20px;
  }
}
@media only screen and (max-width: 686px) {
  .body {
    height: 750px;
  }
  .carousel {
    width: 95%;
  }
  .carousel__item {
    height: 675px;
    padding: 0 60px 0;
    flex-direction: column;
    background-color: transparent;
  }
  .carousel__pagination {
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 0 0 15px 0;
  }
  .image {
    width: 100%;
  }
  .image img {
    width: 75%;
    aspect-ratio: 238 / 284;
    border-radius: 25px;
    box-shadow: 0 6px 4px rgba(0, 0, 0, 0.35);
  }
  .description {
    padding: 10px;
    width: 100%;
  }
  .description p {
    font-size: 14px;
    text-align: center;
    color: var(--text-color);
  }
  .description h1 {
    font-size: 24px;
    text-align: center;
    color: var(--text-color);
  }
  .description .resume {
    padding: 15px 0 28px 0;
  }
  .description .role {
    font-size: 18px;
  }
}
@media only screen and (max-width: 476px) {
  .body {
    height: 750px;
  }
  .carousel {
    width: 95%;
  }
  .carousel__item {
    height: 575px;
    padding: 0 15px 0;
    flex-direction: column;
    background-color: transparent;
  }
  .carousel__pagination {
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 0 0 15px 0;
  }
  .image {
    width: 100%;
  }
  .image img {
    width: 80%;
    aspect-ratio: 238 / 284;
    border-radius: 25px;
    box-shadow: 0 6px 4px rgba(0, 0, 0, 0.35);
  }
  .description {
    padding: 10px;
    width: 100%;
  }
  .description p {
    font-size: 10px;
    text-align: center;
    color: var(--text-color);
  }
  .description h1 {
    font-size: 22px;
    text-align: center;
    color: var(--text-color);
  }
  .description .resume {
    padding: 15px 0 28px 0;
  }
  .description .role {
    font-size: 18px;
  }
}
@media only screen and (max-width: 320px) {
  .body {
    height: 800px;
  }
  .carousel {
    width: 95%;
  }
  .carousel__item {
    height: 575px;
    padding: 0;
    flex-direction: column;
    background-color: transparent;
  }
  .carousel__pagination {
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 0 0 15px 0;
  }
  .image {
    width: 100%;
  }
  .image img {
    width: 90%;
    aspect-ratio: 238 / 284;
    border-radius: 25px;
    box-shadow: 0 6px 4px rgba(0, 0, 0, 0.35);
  }
  .description {
    padding: 10px;
    width: 100%;
  }
  .description p {
    font-size: 8px;
    text-align: center;
    color: var(--text-color);
  }
  .description h1 {
    font-size: 22px;
    text-align: center;
    color: var(--text-color);
  }
  .description .resume {
    padding: 15px 0 28px 0;
  }
  .description .role {
    font-size: 18px;
  }
}
</style>