<script setup>

import Button from "@/components/Button.vue";
</script>
<template>
  <div class="body">
    <div class="container">
      <div class="items">
        <div class="description">
          <h3>Descubra o</h3>
          <h1>
            <span class="highlight">Compromisso</span> com <br>a
            <span class="highlight">Excelência Jurídica</span>
          </h1>
          <div class="image" v-if="isMobile">
            <img src="../assets/images/main-illustration.svg" alt="" />
          </div>
          <p>
            No Escritório de Advocacia Bertani, oferecemos um atendimento personalizado e soluções jurídicas inovadoras para proteger seus interesses e garantir seus direitos. Nossa equipe de advogados é dedicada, experiente e pronta para atender todas as suas necessidades legais.
          </p>
        </div>
        <div class="button-container">
          <Button></Button>
        </div>
      </div>
      <div class="image" v-if="!isMobile">
        <img src="../assets/images/main-illustration.svg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Start',
  data() {
    return {
      isMobile: false,
    };
  },
  mounted() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.isMobile = window.innerWidth <= 833;
    },
  },
};
</script>

<style scoped>
.body {
  margin: 0 auto;
  max-width: 1440px;
  height: 800px;
  padding-top: 90px;
}
.container {
  display: flex;
  padding: 100px 76px 0;
  margin: 0;
  width: 100%;
  flex-direction: row;
}
.items {
  width: 65%;
  display: flex;
  padding-top: 30px;
  flex-direction: column;
}
.description {
  width: 100%;
}
.description h3 {
  padding: 0;
  font-size: 32px;
  font-weight: 800;
  color: var(--text-color);
  font-family: 'Poppins', sans-serif;
}
.description h1 {
  padding: 0;
  font-size: 66px;
  font-weight: 800;
  line-height: 1.0;
  color: var(--text-color);
  font-family: 'Poppins', sans-serif;
}
.highlight {
  color: var(--text-secondary-color);
}
.description p {
  padding: 30px 0 0;
  font-size: 18px;
  color: var(--text-color);
  font-family: 'Poppins', sans-serif;
}
.image {  
  width: 35%;
  display: flex;
  padding-top: 30px;
  justify-content: end;
} 
.image img {
  width: 400px;
  height: 400px;
}
.button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

@media only screen and (min-width: 1024px) and (max-width: 1325px) {
  .body {
    height: 734px;
  }
  .container {
    padding: 100px 56px 0;
  }
  .items {
    width: 60%;
    display: flex;
    padding-top: 30px;
    flex-direction: column;
  }
  .image {  
    width: 40%;
    display: flex;
    padding-top: 30px;
    justify-content: end;
  } 
  .image img {
    width: 360px;
    height: 360px;
  }
  .description h3 {
    font-size: 24px;
    font-weight: 800;
    color: var(--text-color);
    font-family: 'Poppins', sans-serif;
  }
  .description h1 {
    font-size: 44px;
    font-weight: 800;
    line-height: 1.0;
    color: var(--text-color);
    font-family: 'Poppins', sans-serif;
  }
  .description p {
    font-size: 18px;
    color: var(--text-color);
    font-family: 'Poppins', sans-serif;
  }
}

@media only screen and (min-width: 834px) and (max-width: 1023px) {
  .body {
    height: 542px;
  }
  .container {
    padding: 72px 32px 0;
  }
  .items {
    width: 60%;
    display: flex;
    padding-top: 10px;
    flex-direction: column;
  }
  .image {  
    width: 40%;
    display: flex;
    padding-top: 30px;
    justify-content: end;
  } 
  .image img {
    width: 320px;
    height: 320px;
  }
  .description h3 {
    font-size: 22px;
    font-weight: 800;
    color: var(--text-color);
    font-family: 'Poppins', sans-serif;
  }
  .description h1 {
    font-size: 40px;
    font-weight: 800;
    line-height: 1.0;
    color: var(--text-color);
    font-family: 'Poppins', sans-serif;
  }
  .description p {
    width: 90%;
    font-size: 14px;
    color: var(--text-color);
    font-family: 'Poppins', sans-serif;
  }
  .button-container {
    margin-top: 28px;
  }
}

@media only screen and (max-width: 833px) {
  .container {
    flex-direction: column;
    align-items: center;
    padding: 0 30px 0 30px;
  }
  .items {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    align-items: center;
  }
  .description {
    width: 100%;
    text-align: center;
  }
  .description h1 {
    font-size: 36px;
    font-weight: 800;
    line-height: 1.2;
  }
  .description p {
    padding: 20px 50px 0 50px;
    width: 100%;
    font-size: 16px;
    color: var(--text-color);
    font-family: 'Poppins', sans-serif;
  }
  .image {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 20px;
  }
  .image img {
    width: 280px;
    height: 280px;
  }
  .button-container {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

@media only screen and (max-width: 500px) {
  .container {
    flex-direction: column;
    align-items: center;
    padding: 0 30px 0 30px;
  }
  .items {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    align-items: center;
  }
  .description {
    width: 100%;
    text-align: center;
  }
  .description h1 {
    font-size: 36px;
    font-weight: 800;
    line-height: 1.2;
  }
  .description p {
    padding: 20px 20px 0 20px;
    width: 100%;
    font-size: 12px;
    color: var(--text-color);
    font-family: 'Poppins', sans-serif;
  }
  .image {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 20px;
  }
  .image img {
    width: 280px;
    height: 280px;
  }
  .button-container {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

@media only screen and (max-width: 499px) {
  .body {
    height: 720px;
  }
  .container {
    flex-direction: column;
    align-items: center;
    padding: 0 30px 0 30px;
  }
  .items {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    align-items: center;
  }
  .description {
    width: 100%;
    text-align: center;
  }
  .description h3 {
    font-size: 16px;
  }
  .description h1 {
    font-size: 22px;
    font-weight: 800;
    line-height: 1.2;
  }
  .description p {
    padding: 24px 16px 0 16px;
    width: 100%;
    font-size: 12px;
    color: var(--text-color);
    font-family: 'Poppins', sans-serif;
  }
  .image {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 20px;
  }
  .image img {
    width: 233px;
    height: 233px;
  }
  .button-container {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

@media only screen and (max-width: 320px) {
  .body {
    width: 320px;
    height: 620px;
  }
  .container {
    flex-direction: column;
    align-items: center;
    padding: 0 30px 0 30px;
  }
  .items {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    align-items: center;
  }
  .description {
    width: 100%;
    text-align: center;
  }
  .description h3 {
    font-size: 16px;
  }
  .description h1 {
    font-size: 22px;
    font-weight: 800;
    line-height: 1.2;
  }
  .description p {
    padding: 24px 0 0 0;
    width: 100%;
    font-size: 8px;
    color: var(--text-color);
    font-family: 'Poppins', sans-serif;
  }
  .image {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 20px;
  }
  .image img {
    width: 233px;
    height: 233px;
  }
  .button-container {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
</style>
