<template>
  <div id="map" ref="map" style="width: 100%; height: 100%;"></div>
</template>

<script>
import { onMounted, ref, watch } from 'vue';
import L from '../utils/leaflet';

export default {
  name: 'Map',
  props: {
    center: {
      type: Object,
      required: true,
      default: () => ({ lat: 0, lng: 0 }),
    },
    zoom: {
      type: Number,
      required: true,
      default: 13,
    },
    markers: {
      type: Array,
      required: false,
      default: () => [
        { lat: 0, lng: 0, popupText: 'Marker 1' },
        { lat: 0, lng: 0, popupText: 'Marker 2' },
      ],
    },
  },
  setup(props, { emit }) {
    const map = ref(null);
    const markersLayer = ref(null);
    const activeMarkerIndex = ref(0);
    const buttonText = ref('Bertani Brasília');

    onMounted(() => {
      map.value = L.map('map', { zoomAnimation: false }).setView([props.center.lat, props.center.lng], props.zoom);

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      }).addTo(map.value);

      markersLayer.value = L.layerGroup().addTo(map.value);
      updateMarkers([props.markers[activeMarkerIndex.value]]);

      const buttonControl = L.Control.extend({
        options: { position: 'bottomleft' },
        onAdd: function () {
          const button = L.DomUtil.create('button', 'toggle-marker-button');
          button.innerHTML = buttonText.value;
          button.onclick = toggleMarker;
          return button;
        },
      });

      map.value.addControl(new buttonControl());
    });

    const updateMarkers = (markers) => {
      if (!markersLayer.value || !map.value) return;

      markersLayer.value.clearLayers();

      markers.forEach(marker => {
        const popupContent = `<b>${marker.popupText}</b>`;
        L.marker([marker.lat, marker.lng])
            .addTo(markersLayer.value)
            .bindPopup(popupContent)
            .openPopup();
      });

      if (markers.length > 0) {
        map.value.setView([markers[0].lat, markers[0].lng], props.zoom);
      }
    };

    const toggleMarker = () => {
      if (!map.value) return;

      activeMarkerIndex.value = (activeMarkerIndex.value + 1) % props.markers.length;
      updateMarkers([props.markers[activeMarkerIndex.value]]);

      buttonText.value = activeMarkerIndex.value === 0 ? 'Bertani Brasília' : 'Bertani Porto Alegre';
      const button = document.querySelector('.toggle-marker-button');
      if (button) {
        button.innerHTML = buttonText.value;
      }

      const location = activeMarkerIndex.value === 0 ? 'bsb' : 'poa';
      emit('location-change', location);
    };

    watch(() => props.markers, (newMarkers) => {
      if (map.value) {
        updateMarkers([newMarkers[activeMarkerIndex.value]]);
      }
    });

    return {
      map,
    };
  },
}
</script>

<style>
#map {
  height: 100%;
  max-height: 800px;
  width: 100%;
  border-radius: 28px;
}

.toggle-marker-button {
  font-size: 1rem;
  height: 3rem;
  width: 14rem;
  background-color: var(--background-quat-color);
  cursor: pointer;
  border: none;
  overflow: hidden;
  border-radius: 18px;
  color: var(--text-unique-white);
  transition: all 0.5s ease-in-out;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}
</style>
