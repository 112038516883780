<script setup>
</script>

<template>
  <a href="https://www.instagram.com/bertaniadvocacia/" target="_blank">
    <button class="btn">
      Agende sua Consulta
    </button>
  </a>
</template>

<style scoped>
.btn {
  font-family: 'Poppins', sans-serif;
  font-weight: 900;
  border-radius: 22px;
  height: 3rem;
  width: 14rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--button-background);
  color: var(--text-inverse-color);
  transition: background-color 0.3s;
  cursor: pointer;
  border: none;
  box-shadow: 0 4px 6px -2px rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
}

button {
  color: var(--text-inverse-color);
  text-decoration: none;
}

a {
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
}

.btn span {
  font-size: 1.2rem;
}

.btn:hover {
  background-color: var(--button-hover-background);
}

.btn:active {
  background-color: var(--button-click-background);
  box-shadow: 0 6px 6px -2px rgba(0, 0, 0, 0.5);
}

@media (max-width: 768px) {
  .btn {
    height: 5rem;
    width: 20rem;
  }
}
</style>
