<template>
  <div class="body">
    <nav class="navbar">
      <div class="logo">
        <img src="../assets/icons/logo-icon.svg" alt="Logo da Advocacia" />
        <h3>BERTANI</h3>
      </div>
      <div class="hamburger" @click="toggleMenu" aria-label="Menu" role="button">
        <img src="../assets/icons/hamburguer.svg" alt="Menu" />
      </div>
      <div :class="['menus', { 'active': isMenuActive }]">
        <ul>
          <li :class="{ active: activeMenuItem === 'start' }">
            <a href="#start" @click="setActiveMenuItem('start')">Início</a>
          </li>
          <li :class="{ active: activeMenuItem === 'about' }">
            <a href="#about" @click="setActiveMenuItem('about')">Sobre</a>
          </li>
          <li :class="{ active: activeMenuItem === 'team' }">
            <a href="#team" @click="setActiveMenuItem('team')">Equipe</a>
          </li>
          <li :class="{ active: activeMenuItem === 'contact' }">
            <a href="#contact" @click="setActiveMenuItem('contact')">Contato</a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'Navbar',
  data() {
    return {
      isMenuActive: false,
      activeMenuItem: '',
      observer: null,
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuActive = !this.isMenuActive;
    },
    setActiveMenuItem(item) {
      this.activeMenuItem = item;
      this.isMenuActive = false; // Fechar o menu ao selecionar uma opção
    },
    observeSections(entries) {
      let visibleSections = entries.filter(entry => entry.isIntersecting);
      if (visibleSections.length > 0) {
        // Ordenar seções visíveis pela proximidade do centro da viewport
        visibleSections.sort((a, b) => {
          const aDistance = Math.abs(a.boundingClientRect.top + a.boundingClientRect.height / 2 - window.innerHeight / 2);
          const bDistance = Math.abs(b.boundingClientRect.top + b.boundingClientRect.height / 2 - window.innerHeight / 2);
          return aDistance - bDistance;
        });
        this.activeMenuItem = visibleSections[0].target.id;
      }
    },
    checkInitialVisibility() {
      const sections = ['start', 'about', 'team', 'contact'];
      let visibleSection = null;
      sections.forEach(id => {
        const section = document.getElementById(id);
        if (section) {
          const rect = section.getBoundingClientRect();
          const distanceFromCenter = Math.abs(rect.top + rect.height / 2 - window.innerHeight / 2);
          if (!visibleSection || distanceFromCenter < visibleSection.distanceFromCenter) {
            visibleSection = { id, distanceFromCenter };
          }
        }
      });
      if (visibleSection) {
        this.activeMenuItem = visibleSection.id;
      }
    },
    handleClickOutside(event) {
      const navbar = this.$el.querySelector('.navbar');
      if (this.isMenuActive && !navbar.contains(event.target)) {
        this.isMenuActive = false;
      }
    },
  },
  mounted() {
    this.observer = new IntersectionObserver(this.observeSections, {
      root: null,
      rootMargin: '0px',
      threshold: 0.5, // Ajuste o threshold conforme necessário
    });
    const sections = ['start', 'about', 'team', 'contact'];
    sections.forEach(id => {
      const section = document.getElementById(id);
      if (section) {
        this.observer.observe(section);
      }
    });

    this.checkInitialVisibility(); // Verificação inicial
    document.addEventListener('click', this.handleClickOutside); // Adicionar evento para detectar cliques fora
  },
  beforeUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
    document.removeEventListener('click', this.handleClickOutside); // Remover evento ao desmontar o componente
  },
};
</script>

<style scoped>
.hamburger {
  display: none;
  cursor: pointer;
}

.body {
  display: flex;
  position: fixed;
  top: 0;
  left: 50%;
  padding: 0;
  transform: translateX(-50%);
  margin: 0 auto;
  z-index: 4999;
  width: 100%;
  height: 90px;
  background-color: var(--background-color);
  transition: 1s;
}

.navbar {
  display: flex;
  position: fixed;
  top: 0;
  left: 50%;
  padding: 0;
  transform: translateX(-50%);
  margin: 0 auto;
  z-index: 5000;
  width: 1440px;
  height: 90px;
  background-color: transparent;
  transition: 1s;
}

.logo {
  width: 60%;
  padding: 36px 0 36px 120px;
  display: flex;
  align-items: center;
  flex-direction: row;
  transition: 1s;
}

.logo h3 {
  color: var(--text-color);
  font-size: 2rem;
  font-family: 'Lato', sans-serif;
  font-weight: 800;
  margin: 0;
}

.logo img {
  height: 48px;
  width: 48px;
  margin-right: 10px;
}

.menus {
  width: 40%;
  padding: 36px 120px 36px 0px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row-reverse;
  transition: 1s;
}

.menus ul {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  position: relative;
  list-style: none;
  align-items: center;
  justify-content: center;
}

.menus li {
  padding: 5px 48px;
  position: absolute;
}

.menus li:nth-child(1) { left: 0; }
.menus li:nth-child(2) { left: 25%; }
.menus li:nth-child(3) { left: 50%; }
.menus li:nth-child(4) { left: 75%; }

.menus a {
  font-size: 24px;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
  padding: 0 10px 5px;
  text-decoration: none;
  color: var(--text-color);
}

.menus a:hover {
  font-weight: 700;
  text-decoration: none;
  border-bottom-width: 5px;
  border-bottom-style: solid;
  border-bottom-color: var(--button-hover-background);
}

.menus a:active {
  font-weight: 700;
  text-decoration: none;
  border-bottom-width: 5px;
  border-bottom-style: solid;
  border-bottom-color: var(--button-click-background);
}

.menus li.active a {
  font-weight: 700;
  text-decoration: none;
  border-bottom-width: 5px;
  border-bottom-style: solid;
  border-bottom-color: var(--button-click-background);
}

@media only screen and (min-width: 1440px) and (max-width: 1439px) {
  .navbar {
    width: 100%;
    left: 0;
    transform: none;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1399px) {
  .navbar {
    width: 100%;
    left: 0;
    transform: none;
  }
  .logo {
    width: 40%;
    padding: 26.5px 0 26.5px 32px;
  }
  .menus {
    width: 60%;
    padding: 26.5px 102px 26.5px 0;
  }
}

@media only screen and (min-width: 834px) and (max-width: 1023px) {
  .navbar {
    width: 100%;
    left: 0;
    transform: none;
  }
  .logo {
    width: 20%;
    padding: 26.5px 0 26.5px 32px;
  }
  .menus {
    width: 80%;
    padding: 26.5px 40px 26.5px 140px;
  }
  .menus li {
    padding: 5px 20px;
    position: absolute;
  }
}

@media only screen and (max-width: 833px) {
  .navbar {
    width: 100%;
    left: 0;
    transform: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
  }
  .logo {
    width: auto;
    padding: 20px 0;
  }
  .logo h3 {
    color: var(--text-unique-white);
    font-size: 36px;
    font-family: 'Lato', sans-serif;
    font-weight: 800;
    margin: 0;
  }
  .hamburger {
    display: block;
    cursor: pointer;
    padding: 0;
  }
  .hamburger img {
    width: 33px;
    height: 29px;
  }
  .menus {
    display: none;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 90px;
    right: 0;
    background-color: var(--background-color);
  }
  .menus.active {
    display: flex;
    padding: 0;
  }
  .menus ul {
    align-items: end;
    flex-direction: column;
    width: 100%;
    padding: 0;
  }
  .menus li {
    padding: 10px;
    margin-right: 30px;
    align-items: end;
    position: relative;
  }
  .menus li:nth-child(1) { left: 0; }
  .menus li:nth-child(2) { left: 0; }
  .menus li:nth-child(3) { left: 0; }
  .menus li:nth-child(4) { left: 0; }
  .menus a {
    font-size: 20px;
    padding: 10px;
    display: block;
    width: 100%;
  }
}

@media only screen and (max-width: 320px) {
  .navbar {
    width: 100%;
    left: 0;
    transform: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
  }
  .logo {
    width: auto;
    padding: 20px 0;
  }
  .logo h3 {
    color: var(--text-unique-white);
    font-size: 26px;
    font-family: 'Lato', sans-serif;
    font-weight: 800;
    margin: 0;
  }
  .logo img {
    height: 36px;
    width: 36px;
  }
  .hamburger {
    display: block;
    cursor: pointer;
    padding: 0;
  }
  .hamburger img {
    width: 33px;
    height: 29px;
  }
  .menus {
    display: none;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 90px;
    right: 0;
    background-color: var(--background-color);
  }
  .menus.active {
    display: flex;
    padding: 0;
  }
  .menus ul {
    align-items: end;
    flex-direction: column;
    width: 100%;
    padding: 0;
  }
  .menus li {
    padding: 10px;
    margin-right: 30px;
    align-items: end;
    position: relative;
  }
  .menus li:nth-child(1) { left: 0; }
  .menus li:nth-child(2) { left: 0; }
  .menus li:nth-child(3) { left: 0; }
  .menus li:nth-child(4) { left: 0; }
  .menus a {
    font-size: 20px;
    padding: 10px;
    display: block;
    width: 100%;
  }
}
</style>
