<template>
  <div class="body">
    <div class="container">
      <div class="image-section">
        <Map :center="mapCenter" :zoom="mapZoom" :markers="mapMarkers" @location-change="handleLocationChange" />
      </div>
      <div class="content-section">
        <div class="top-content">
          <div class="social">
            <img src="../assets/icons/instagram-icon.svg" alt="Instagram" />
            <a href="https://www.instagram.com/bertaniadvocacia/" target="_blank" rel="noopener noreferrer"><span class="link">@bertaniadvocacia</span></a>
          </div>
          <div class="social">
            <img src="../assets/icons/whatsapp-icon.svg" alt="Whatsapp" />
            <a href="https://wa.me/5554999801511?text=Ol%C3%A1,%20Jo%C3%A3o%20Pedro!%20Encontrei%20seu%20contato%20no%20site%20e%20gostaria%20de%20tirar%20algumas%20d%C3%BAvidas.%20Voc%C3%AA%20pode%20me%20ajudar?" target="_blank" rel="noopener noreferrer"><span class="link">55 54 99980 1511</span></a>
          </div>
          <div class="social">
            <img src="../assets/icons/email-icon.svg" alt="Email" />
            <a href="mailto:joao.bertani@bertaniadvocacia.com.br"><span class="link">joao.bertani@bertaniadvocacia.com.br</span></a>
          </div>
        </div>
        <div class="bottom-content">
          <img src="../assets/icons/logo-icon.svg" alt="Location" />
          <div class="local">
            <span>Seg - Sex: 8:30 - 12:00 e 13:30-18:00</span>
            <p>{{ currentAddress }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Map from '../components/Map.vue';

export default {
  name: 'ParentComponent',
  components: {
    Map,
  },
  data() {
    return {
      mapCenter: { lat: -30.044465989596077, lng: -51.229689586529695 },
      mapZoom: 13,
      mapMarkers: [
        { lat: -30.044465989596077, lng: -51.229689586529695, popupText: 'Bertani Porto Alegre' },
        { lat: -15.789884257093098, lng: -47.88406384473191, popupText: 'Bertani Brasília' },
      ],
      addresses: {
        poa: 'R. Celeste Gobato, 129 - sala 502 - Praia de Belas, Porto Alegre - RS, 90110-160',
        bsb: 'SNH, Quadra 2, Bloco F, sala 706 - Asa Norte, Brasília - DF, 70702-914'
      },
      currentAddress: 'R. Celeste Gobato, 129 - sala 502 - Praia de Belas, Porto Alegre - RS, 90110-160',
    };
  },
  methods: {
    handleLocationChange(location) {
      if (location === 'poa') {
        this.currentAddress = this.addresses.bsb;
      } else if (location === 'bsb') {
        console.log("boa")
        this.currentAddress = this.addresses.poa;
      }
    },
  },
};
</script>

<style scoped>
  .body {
    height: 700px;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
  }

  .container {
    display: grid;
    grid-template-columns: 3fr 2fr;
    gap: 30px;
    width: 80%;
    height: 90%;
    max-width: 1200px;
    margin: auto;
  }

  .image-section {
    grid-row: 1 / 3;
    border-radius: 28px;
    box-shadow: -16px 16px 0 4px var(--background-quat-color);
  }

  .content-section {
    display: grid;
    grid-template-rows: 2fr 2fr;
    gap: 80px;
  }

  .top-content {
    margin-top: 80px;
    display: grid;
    gap: 30px;
  }

  .bottom-content {
    display: flex;
    justify-content: start;
    align-items: end;
  }

  .bottom-content span {
    color: var(--text-color);
    font-size: 1rem;
  }

  .bottom-content p{
    font-size: 1.2rem;
    color: var(--text-color);
  }

  .bottom-content img {
    height: 80px;
    width: 60px;
    margin-right: 10px;
  }

  .social {
    display: flex;
    align-items: center;
  }

  .social img{
    height: 40px;
    width: 40px;
    margin-right: 10px;
  }

  .social span {
    color: var(--text-color);
    font-size: 1.4rem;
  }

  .social .link:hover {
    color: var(--primary-color);
  }

  @media (max-width: 768px) {
    .body {
      margin-bottom: 40px;
    }

    .container {
      grid-template-columns: 1fr;
      grid-template-rows: auto auto auto;
    }

    .image-section {
      grid-row: 1 / 3;
      box-shadow: -10px 10px 0 1px var(--background-quat-color);
    }

    .content-section {
      grid-template-columns: 1fr;
      grid-template-rows: auto auto;
      grid-row: 3 / 4;
      gap: 20px;
    }

    .top-content {
      gap: 0;
    }

    .bottom-content img {
      height: 50px;
      width: 40px;
      margin-right: 10px;
    }

    .top-content {
      margin-top: 0;
    }
  }
</style>