<template>
  <div class="body">
    <div class="container" :class="{ mobile: isMobile }">
      <div class="image" v-if="!isMobile">
        <div class="item-1">
          <img src="../assets/icons/logo-icon.svg" alt="Logo da Advocacia">
        </div>
      </div>
      <div class="description">
        <h1>
          Sobre a <span class="highlight">Bertani</span>
        </h1>
        <div class="item-1" v-if="isMobile">
          <img src="../assets/icons/logo-icon.svg" alt="Logo da Advocacia">
        </div>
        <p>
          Somos um escritório de advocacia especializado em Direito Empresarial, Público/Administrativo, Tributário, Agrário, Internacional e Civil Sucessório. Oferecemos soluções jurídicas adequadas com atendimento personalizado.
          <br>Nossa atuação inclui consultoria, assistência jurídica e execução de processos, sempre buscando a excelência para nossos clientes. Combinamos conhecimento jurídico com pessoalidade nas relações.
          <br>Com sedes em Porto Alegre e Brasília, e parcerias internacionais em Lisboa e Madrid, contamos com uma rede de especialistas para oferecer atendimento com ética, responsabilidade, transparência e total dedicação.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Start',
  data() {
    return {
      isMobile: false,
    };
  },
  mounted() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.isMobile = window.innerWidth <= 1064;
    },
  },
};
</script>

<style scoped>
.body {
  margin: 0 auto;
  max-width: 1440px;
  height: 800px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.container {
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 120px 0;
  justify-content: center;
}
.image {
  width: 40%;
  height: 50vh;
  position: relative;
  margin-right: 2%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.item-1 {
  position: relative;
  z-index: 1000;
  width: 95%;
  aspect-ratio: 450 / 471;
  border-radius: 28px;
  background-color: var(--background-quat-color);
  
  box-shadow: -36px -32px rgba(239, 188, 115, 1);

  display: flex;
  align-items: center;
  justify-content: center;
}
.item-1 img {
  width: 65%;
  aspect-ratio: 277 / 350;
}
.description {
  text-align: end;
  width: 60%;
}
.description h1 {
  padding: 0;
  font-size: 66px;
  font-weight: 700;
  line-height: 1.0;
  color: var(--text-color);
  font-family: 'Poppins', sans-serif;
}
.highlight {
  color: var(--text-secondary-color);
}
.description p {
  padding: 26px 0 0;
  font-size: 18px;
  color: var(--text-color);
  font-family: 'Poppins', sans-serif;
  text-align: end;
}
@media only screen and (max-width: 1224px) {
  .container {  
    padding: 0 33px 0;
  }
  .item-1 {
    margin-left: 36px;
  }
}

@media only screen and (max-width: 1064px) {
  .container {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0 100px 0;
  }
  .image {
    width: 100%;
    margin-right: 0;
  }
  .description {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .description .item-1 {
    margin-top: 64px;
    width: 50%;
    aspect-ratio: 450 / 471;
    border-radius: 28px;
    background-color: var(--background-quat-color);
    
    box-shadow: -36px -32px rgba(239, 188, 115, 1);

    display: flex;
    align-items: center;
    justify-content: center;
  }
  .item-1 img {
    width: 65%;
    aspect-ratio: 277 / 350;
  }
  .description h1 {
    font-size: 48px;
  }
  .description p {
    padding: 20px 0 36px;
    font-size: 16px;
    text-align: center;
  }
}

@media only screen and (max-width: 834px) {
  .container {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0 50px 0;
  }
  .image {
    width: 100%;
    margin-right: 0;
  }
  .description {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .description .item-1 {
    margin-top: 64px;
    width: 55%;
    aspect-ratio: 450 / 471;
    border-radius: 28px;
    background-color: var(--background-quat-color);
    
    box-shadow: -36px -32px rgba(239, 188, 115, 1);

    display: flex;
    align-items: center;
    justify-content: center;
  }
  .item-1 img {
    width: 65%;
    aspect-ratio: 277 / 350;
  }
  .description h1 {
    margin-top: 40px;
    font-size: 36px;
  } 
  .description p {
    padding: 20px 0 36px;
    font-size: 16px;
    text-align: center;
  }
}

@media only screen and (max-width: 500px) {
  .container {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0 16px 0;
  }
  .image {
    width: 100%;
    margin-right: 0;
  }
  .description {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .description .item-1 {
    margin-top: 64px;
    width: 60%;
    aspect-ratio: 450 / 471;
    border-radius: 28px;
    background-color: var(--background-quat-color);
    
    box-shadow: -20px -18px rgba(239, 188, 115, 1);

    display: flex;
    align-items: center;
    justify-content: center;
  }
  .item-1 img {
    width: 65%;
    aspect-ratio: 277 / 350;
  }
  .description h1 {
    margin-top: 40px;
    font-size: 32px;
  } 
  .description p {
    padding: 20px 0 20px;
    font-size: 12px;
    text-align: center;
  }
}
</style>