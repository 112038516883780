<template>
  <div id="app">
    <Navbar></Navbar>
    <Start id="start"></Start>
    <About id="about"></About>
    <Specialty></Specialty>
    <Team id="team"></Team>
    <Contact id="contact"></Contact>
    <Footer></Footer>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Start from "@/components/Start.vue";
import About from "@/components/About.vue";
import Specialty from "@/components/Specialty.vue";
import Team from "@/components/Team.vue"
import Contact from "@/components/Contact.vue"
import Footer from "@/components/Footer.vue";

export default {
  name: "App",
  components: {Navbar, Start, About, Footer, Specialty, Team, Contact},
  mounted() {
    this.applySavedTheme()
  },
  methods:{
    applySavedTheme(){
      const savedTheme = localStorage.getItem("theme")
      savedTheme ?  this.setTheme(savedTheme) : this.applySystemTheme()
    },
    applySystemTheme(){
      const prefersDarkTheme = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
      const theme = prefersDarkTheme ? 'dark' : 'light'
      this.setTheme(theme)
    },
    setTheme(theme){
      document.documentElement.setAttribute('data-theme', theme)
    }
  }
}
</script>

<style scoped>
#contact {
  margin-bottom: 180px;
  padding-top: 70px;
}

#team {
  margin-bottom: -70px;
}

@media (max-width: 768px) {
  #contact {
    margin-bottom: 80px;
    padding-top: 40px;
  }

  #team {
    margin-bottom: -20px;
  }
}
</style>
